import React, { useEffect, useState } from 'react';
import RequestHandler from '../../../components/RequestHandler/RequestHandler';
import {
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { When } from 'react-if';
import SaveIcon from '@material-ui/icons/Save';
import { ArrowLeftIcon } from '@material-ui/pickers/_shared/icons/ArrowLeftIcon';
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
import clsx from 'clsx';
import {
  AvailableWarehouseProductsItem,
  B2BShipmentMethodTypeEnum,
  B2BShipmentPoint,
  B2BWrapTypeEnum,
  B2BWrapWayEnum,
  CreateB2BOrderInput,
  File,
  Warehouse,
  Warehouse_Operations,
  useCreateB2BOrderMutation,
  useGetAvailableWarehouseProductsQuery,
  useGetB2BShipmentPointsQuery,
  useGetWarehousesListQuery,
} from '../../../generated/graphql';
import B2bShipmentSendFormStep1 from './B2bShipmentSendFormStep1';
import B2bShipmentSendFormStep2 from './B2bShipmentSendFormStep2';
import B2bShipmentSendFormStep3 from './B2bShipmentSendFormStep3';
import B2bShipmentSendFormStep4 from './B2bShipmentSendFormStep4';
import B2bShipmentSendFormStep5 from './B2bShipmentSendFormStep5';

import { B2bShipmentSendFormValidationSchemas } from '../../../utils/validationSchemes';
import { useHistory } from 'react-router';
import { LIMIT_ITEM_PAGE, TRoutes } from '../../../utils/helpers';
import { GET_B2B_ORDER_LIST_QUERY } from '../../../GraphQL/queries/getB2BOrderList';
import { LOCAL_STORAGE_KEYS } from '../../../utils/constants';

export interface ExtendedWarehouseProductsItem
  extends AvailableWarehouseProductsItem {
  quantity: number;
}

export interface IB2bShipmentsValues {
  warehouseId: string;
  products: ExtendedWarehouseProductsItem[];
  packageType: string;
  packageInstruction: string;
  fileLabel: File | null;
}

const initialValues = {
  comment: '',
  products: [],
  shipmentInfo: {
    driverInfo: {
      carModel: '',
      carNumber: '',
      fullName: '',
      mobileNumber: '',
    },
    scheduledDate: new Date().toString(),
    scheduledTime: new Date().toString(),
    typeMethod: B2BShipmentMethodTypeEnum.PicknPack,
  },
  shipmentPointId: null,
  technicalTaskFile: null,
  warehouseId: null,
  wrapType: B2BWrapTypeEnum.Boxes,
  wrapWay: B2BWrapWayEnum.MonoboxOrMonopallet,
};

const B2bShipmentSendForm = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [warehouseList, setWarehouseList] = useState<Warehouse[]>([]);
  const [warehouseId, setWarehouseId] = useState<string>('');
  const [availableProducts, setAvailableProducts] = useState<
    AvailableWarehouseProductsItem[]
  >([]);
  const [isComment, setIsComment] = useState<boolean>(false);

  const itemsLimit = localStorage.getItem(
    LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY,
  );

  const { data: warehouses } = useGetWarehousesListQuery({
    variables: { operation: Warehouse_Operations.Fulfillment },
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const { data: avaliableProductsData } = useGetAvailableWarehouseProductsQuery(
    {
      variables: { warehouseId },
      skip: !warehouseId,
      fetchPolicy: 'network-only',
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    },
  );

  const { data: B2BShipmentPointsData } = useGetB2BShipmentPointsQuery({
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const [createB2BOrderMutation, { loading: createB2BOrderMutationLoading }] =
    useCreateB2BOrderMutation();

  useEffect(() => {
    if (warehouses?.getWarehousesList?.rows)
      setWarehouseList(warehouses?.getWarehousesList?.rows as Warehouse[]);
  }, [warehouses]);

  useEffect(() => {
    if (avaliableProductsData?.getAvailableWarehouseProducts)
      setAvailableProducts(
        avaliableProductsData?.getAvailableWarehouseProducts as AvailableWarehouseProductsItem[],
      );
  }, [avaliableProductsData]);

  const handleSubmit = (values: CreateB2BOrderInput) => {
    const filteredProducts = values?.products?.map((product) => ({
      productId: product?.productId,
      quantity: product?.quantity,
    }));

    createB2BOrderMutation({
      variables: {
        input: {
          ...values,
          products: filteredProducts,
        },
      },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(t('app.orderSuccessfullyCreated'), {
            variant: 'success',
          });
          history.push(TRoutes.B2B_ORDERS);
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      refetchQueries: [
        {
          query: GET_B2B_ORDER_LIST_QUERY,
          variables: {
            filter: {
              offset: 0,
              limit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
              search: '',
            },
          },
        },
      ],
    });
  };

  const stepCount = 4;

  return (
    <RequestHandler loading={false}>
      <Typography variant='h2' className={classes.mainTitle} align='center'>
        {t('app.newShippingB2b')}
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: CreateB2BOrderInput, helpers) => {
          if (activeStep < stepCount) {
            setActiveStep((prevState) => prevState + 1);
            helpers.setSubmitting(false);
          } else {
            handleSubmit(values);
          }
        }}
        enableReinitialize
        validationSchema={B2bShipmentSendFormValidationSchemas[activeStep]}
      >
        {({ errors }) => {
          return (
            <Form noValidate autoComplete='off'>
              <Stepper orientation='vertical' activeStep={activeStep}>
                <Step>
                  <StepLabel>{t('app.from')}</StepLabel>
                  <StepContent>
                    <B2bShipmentSendFormStep1
                      warehouseList={warehouseList as Warehouse[]}
                      setWarehouseId={setWarehouseId}
                    />
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>{t('app.product')}</StepLabel>
                  <StepContent>
                    <B2bShipmentSendFormStep2
                      products={
                        availableProducts as AvailableWarehouseProductsItem[]
                      }
                    />
                  </StepContent>
                </Step>

                <Step>
                  <StepLabel>{t('app.task')}</StepLabel>
                  <StepContent>
                    <B2bShipmentSendFormStep3
                      isComment={isComment}
                      setIsComment={setIsComment}
                    />
                  </StepContent>
                </Step>

                <Step>
                  <StepLabel>{t('app.to')}</StepLabel>
                  <StepContent>
                    <B2bShipmentSendFormStep4
                      shipmentPoints={
                        B2BShipmentPointsData?.getB2BShipmentPoints
                          ?.rows as B2BShipmentPoint[]
                      }
                    />
                  </StepContent>
                </Step>
                <Step>
                  <StepLabel>{t('app.shipmentMethod')}</StepLabel>
                  <StepContent>
                    <B2bShipmentSendFormStep5 />
                  </StepContent>
                </Step>
              </Stepper>

              <div className={classes.actions}>
                <When condition={Boolean(Object.values(errors).length)}>
                  <p className={classes.errorsMessage}>
                    {t('app.checkAllFieldsAbove')}
                  </p>
                </When>

                {/*eslint-disable-next-line */}
                {/* {values.products.map((product: any, index: any) => {
                  if (product.quantity > values?.selected[index]?.maxQuantity) {
                    setErrorQuantity(true);
                    return (
                      <p className={classes.errorsMessage}>
                        {t('app.quantityNotExceedAvailable')}
                      </p>
                    );
                  } else {
                    setErrorQuantity(false);
                  }
                })} */}

                <Button
                  className={clsx(classes.arrangeAction, classes.action)}
                  variant='contained'
                  type='button'
                  disabled={activeStep === 0}
                  onClick={() => setActiveStep((prevState) => prevState - 1)}
                  startIcon={<ArrowLeftIcon />}
                >
                  {t('app.back')}
                </Button>

                <Button
                  className={clsx(classes.arrangeAction, classes.action)}
                  type={'submit'}
                  variant='contained'
                  disabled={createB2BOrderMutationLoading}
                  startIcon={
                    activeStep < stepCount ? <ArrowRightIcon /> : <SaveIcon />
                  }
                  onClick={() => {
                    window.scrollTo(0, 100);
                  }}
                >
                  {activeStep < stepCount ? t('app.next') : 'Создать заявку'}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </RequestHandler>
  );
};

export default B2bShipmentSendForm;
