import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment';
import { LIMIT_ITEM_PAGE, TRoutes } from '../../utils/helpers';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Transaction } from '../../generated/graphql';
import { DATE_TIME_FORMAT, PAYMENT_TYPES } from '../../utils/constants';
import { BoxCentered } from '../BoxCentered/BoxCentered';
import { Pagination } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    amount: {
      whiteSpace: 'nowrap',
      padding: '0 16px',
    },
    balance: {
      whiteSpace: 'nowrap',
      padding: '0 16px',
    },
    tableCell: {
      padding: '0 16px',
    },
    pagination: {
      fontWeight: 'bold',
      marginTop: 32,
      '& .MuiPagination-ul': {
        [theme.breakpoints.down('xs')]: {
          flexWrap: 'nowrap',
          justifyContent: 'center',
        },
      },
      '& .MuiButtonBase-root': {
        width: 44,
        height: 44,
        borderRadius: '50%',
        padding: 0,
        margin: 0,
        marginRight: 8,

        [theme.breakpoints.down('xs')]: {
          margin: 0,
          width: 35,
          height: 35,
        },
      },
      '& .MuiPaginationItem-ellipsis': {
        width: 12,
        height: 44,
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        minWidth: 'auto',
        marginRight: 8,
      },
      '& .MuiPaginationItem-icon': {
        fontSize: '25px',
      },
    },
  }),
);

const getDateTime = (transaction: Transaction) => {
  if (transaction.createdAt) {
    return moment(Number(transaction.createdAt)).format(DATE_TIME_FORMAT);
  }
  return t('app.unknown');
};

const getPaymentTypeText = (transaction: Transaction) => {
  if (transaction.paymentType) {
    const type = t(PAYMENT_TYPES[transaction.paymentType]);
    if (type) return type;
  }

  if (transaction.paymentType === 'ADMIN_OPERATION' && transaction.reason) {
    let reason = t('app.unknown');
    if (transaction.amount || transaction.amount === 0) {
      reason = t('app.manualWithdrawal');
      if (transaction.amount > 0) {
        reason = t('app.manualDeposit');
      }
    }
    return reason;
  }
  return t('app.unknownPaymentType');
};

const getType = (transaction: Transaction) => {
  const paymentTypeText = getPaymentTypeText(transaction);
  return transaction.parcelId || transaction?.warehouseShipmentOrder?.id ? (
    <Tooltip title={paymentTypeText}>
      <span>{paymentTypeText}</span>
    </Tooltip>
  ) : transaction.cdekParcelId ? (
    <Tooltip title={paymentTypeText}>
      <span>
        {transaction.paymentType === 'CDEK'
          ? `${t('app.deduction')} `
          : transaction.paymentType === 'REFUND'
          ? `${t('app.refund')} `
          : ''}
        CDEK &nbsp;
      </span>
    </Tooltip>
  ) : (
    paymentTypeText
  );
};

const getDetails = (transaction: Transaction) => {
  const returnTitleId = transaction?.returnShipment?.barcodeId?.split('.')[0];
  const b2bShipmentIdTitle = transaction?.b2bOrder?.barcodeId?.split('.')[0];
  if (
    (transaction.paymentType === 'ADMIN_OPERATION' ||
      transaction.paymentType === 'WAREHOUSE_STORAGE' ||
      transaction.paymentType === 'REFUND' ||
      transaction.paymentType === 'WEIGHT_ADJUSTMENT' ||
      transaction.paymentType === 'ORDER_PAYMENT' ||
      transaction.paymentType === 'REFILL') &&
    transaction.reason
  ) {
    return transaction.reason;
  }

  if (transaction.paymentType === 'SERVICE_FEE' && transaction.reason) {
    return `${transaction.reason} ${t('app.pcs')}`;
  }

  if (transaction.parcelId || transaction?.warehouseShipmentOrder?.id) {
    return (
      <Link
        to={
          transaction.parcelId
            ? TRoutes.SHIPMENT_DETAILS.replace(
                ':id',
                transaction?.parcelId?.toString() as string,
              )
            : TRoutes.SHIPMENTS_FROM_WAREHOUSE.replace(
                ':id',
                transaction?.warehouseShipmentOrder?.id as string,
              )
        }
      >
        №
        {transaction.parcelId ||
          transaction?.warehouseShipmentOrder?.barcodeId?.substring(
            0,
            transaction?.warehouseShipmentOrder?.barcodeId.length - 4,
          )}
      </Link>
    );
  }

  if (transaction?.undeclaredShipment?.id) {
    return (
      <Link
        to={`${
          TRoutes.UNDECLARED_ARRIVAL_DETAILS_WITHOUT_ID
        }${transaction?.undeclaredShipment?.id.toString()}`}
      >
        {`UND-${transaction?.undeclaredShipment?.id}`}
      </Link>
    );
  }

  if (transaction?.warehouseShipment?.id) {
    return (
      <Link
        to={TRoutes.WAREHOUSE_SHIPMENT_DETAILS.replace(
          ':id',
          transaction?.warehouseShipment?.id.toString(),
        )}
      >
        №
        {transaction?.warehouseShipment?.barcode?.substring(
          0,
          transaction?.warehouseShipment?.barcode?.length - 4,
        )}
      </Link>
    );
  }

  if (transaction?.returnShipment?.id) {
    return (
      <Link
        to={`${
          TRoutes.RETURN_DETAILS_WITHOUT_ID
        }${transaction?.returnShipment?.id.toString()}`}
      >
        №{returnTitleId}
      </Link>
    );
  }

  if (transaction?.b2bOrder?.id) {
    return (
      <Link
        to={`${
          TRoutes.B2B_ORDERS_DETAILS_WITHOUT_ID
        }${transaction?.b2bOrder?.id.toString()}`}
      >
        {b2bShipmentIdTitle}
      </Link>
    );
  }

  if (transaction.cdekParcelId) {
    return (
      <span>
        <Link
          to={TRoutes.WAREHOUSE_SEND_DETAILS.replace(
            ':id',
            transaction.cdekParcelId.toString(),
          )}
        >
          PP-{transaction.cdekParcelId}
        </Link>
      </span>
    );
  }
};

const getAmount = (transaction: Transaction) => {
  let amount = t('app.unknown');
  if (transaction.amount || transaction.amount === 0) {
    amount = `${transaction.amount} ₽`;
    if (transaction.amount > 0) {
      amount = '+' + amount;
    }
  }
  return amount;
};

const getBalance = (transaction: Transaction) => {
  let balance = t('app.unknown');
  if (transaction.balance || transaction.balance === 0) {
    balance = `${transaction.balance} ₽`;
    if (transaction.balance > 0) {
      balance = '+' + balance;
    }
  }
  return balance;
};

const PaymentsTable: React.FC<{
  transactions: Transaction[];
  count: number;
  page: number;
  setFilter: React.Dispatch<React.SetStateAction<{ page: number }>>;
}> = ({ transactions, count, page, setFilter }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (!transactions.length) {
    return <BoxCentered>{t('app.noData')}</BoxCentered>;
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>{t('app.dateTime')}</TableCell>
              <TableCell align='center'>{t('app.transactionType')}</TableCell>
              <TableCell align='center'>{t('app.details')}</TableCell>
              <TableCell align='center'>{t('app.amount')}</TableCell>
              <TableCell align='center'>{t('app.balance')}</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {transactions.map(
              (transaction) =>
                transaction && (
                  <TableRow key={transaction.id}>
                    <TableCell align='center' className={classes.tableCell}>
                      {getDateTime(transaction)}
                    </TableCell>
                    <TableCell align='center' className={classes.tableCell}>
                      <BoxCentered>{getType(transaction)}</BoxCentered>
                    </TableCell>
                    <TableCell align='center' className={classes.tableCell}>
                      <BoxCentered>{getDetails(transaction)}</BoxCentered>
                    </TableCell>
                    <TableCell align='center' className={classes.amount}>
                      {getAmount(transaction)}
                    </TableCell>
                    <TableCell align='center' className={classes.balance}>
                      {getBalance(transaction)}
                    </TableCell>
                  </TableRow>
                ),
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        className={classes.pagination}
        count={Math.ceil(count / LIMIT_ITEM_PAGE)}
        page={page}
        onChange={(_, page) => {
          setFilter((state) => ({
            ...state,
            page: page,
          }));
        }}
        color='primary'
      />
    </>
  );
};

export default PaymentsTable;
